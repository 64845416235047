<template>
    <div class="box-wrapper" >
        <div
            class="box"
            :class="colourClass"
            :style="{top: bTop + 'px', left: bLeft + 'px', width: bWidth + 'px', height: bHeight + 'px'}"
            @click.stop="toggleLabel"
            @contextmenu.stop="remove"
            >
        </div>
    </div>
</template>

<script>
    export default {
        name: "Box",
        props: [
            'b-top', 'b-left', 'b-width', 'b-height', 'defaultColor'
        ],
        data() {
            return {
                colourClass: "",
                colourClasses: [ 'red', 'blue' ]
            }
        },
        mounted() {
            this.colourClass = this.defaultColor;
        },
        methods: {
            remove(e) {
                e.preventDefault();
                this.$emit('remove');
            },
            toggleLabel() {
                if (this.colourClass == "red") {
                    this.colourClass = 'blue'
                } else {
                    this.colourClass = 'red';
                }
                
                this.$emit('changeColour', this.colourClass)
            }
        },
        watch:{
            defaultColor() {
                // TODO: To do this properly use something unique insteaad of i as the loop key so it re-mounts.
                this.colourClass = this.defaultColor
            }
        }
    }
</script>

<style lang="scss" scoped>
    .box {
        position: absolute;
        border: 2px #90ee90 solid;

        &:hover, &.active {
            cursor: pointer;
            background-color: rgba(144, 238, 144, .2);
        }

        z-index: 3;
    }

    .red {
        border: 2px #ee9090 solid;
        &:hover, &.active {
            background-color: rgba(238, 144, 144, 0.2);
        }
    }
    .blue {
        border: 2px #4fd3ff solid;
        &:hover, &.active {
            background-color: rgba(94, 196, 255, 0.2);
        }
    }
</style>