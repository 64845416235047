<template>
    <div id="labeler">
        <div
            ref="wrapper"
            id="image-wrapper"
            @mousedown="startDrawingBox"
            @mousemove="changeBox"
            @mouseup="stopDrawingBox"
            >
            <!-- The image -->
            <div style="height: 1px; width: 1px;">
                <img ref="img" :src="image" alt="" width="800px" class="nodrag">
            </div>
            <!-- The one when drawing -->
            <bounding-box
                v-if="drawingBox.active"
                :b-width="drawingBox.width"
                :b-height="drawingBox.height"
                :b-top="drawingBox.top"
                :b-left="drawingBox.left"
            />
            <!-- The one when rendering -->
            <bounding-box 
                v-for="(box, i) in boxes" 
                :key="i" 
                :b-top="box.top" 
                :b-left="box.left" 
                :b-width="box.width" 
                :b-height="box.height" 
                @changeColour="box.color = $event;boxesChanged()" 
                @remove="boxes.splice(i, 1)"
                :defaultColor="box.color"
            />
        </div>
    </div>
</template>

<script>
import BoundingBox from "./BoundingBox.vue";
import { pick } from 'lodash';

export default {
    components: { BoundingBox },
    props: {
        image: {
            type: String,
            default: ""
        },
    },
    data() {
        return {
            drawingBox: {
                active: false,
                top: 0,
                left: 0,
                height: 0,
                width: 0
            },
            boxes: []
        }
    },
    mounted() {
    },
    computed: {
        imgProperties() {
            return {
                width: this.$refs.img.width,
                height: this.$refs.img.height,
                naturalWidth: this.$refs.img.naturalWidth,
                naturalHeight: this.$refs.img.naturalHeight,
            }
        },
    },
    methods: {
        setBoxes(boxes) {
            // Parse them from relative.
            let { width, height, naturalWidth, naturalHeight } = this.imgProperties;

            this.boxes = boxes.map(x => ({
                ...x,
                height: x.height * height,  // x should be a scalar
                width: x.width * width,     // x should be a scalar
                top: x.top * height,        // x should be a scalar
                left: x.left * width,       // x should be a scalar
            }));
        },
        startDrawingBox(e) {
            this.drawingBox = {
                width: 0,
                height: 0,
                top: (e.clientY - this.$refs.wrapper.offsetTop),
                left: (e.clientX - this.$refs.wrapper.offsetLeft),
                active: true,
            };
        },
        changeBox(e) {
            if (this.drawingBox.active) {
                this.drawingBox = {
                    ...this.drawingBox,
                    width: (e.clientX - this.drawingBox.left - this.$refs.wrapper.offsetLeft),
                    height: (e.clientY - this.drawingBox.top - this.$refs.wrapper.offsetTop),
                };
            }
        },
        stopDrawingBox() {
            if (this.drawingBox.active) {
                if (this.drawingBox.width > 5) {
                    this.boxes.push({...pick(this.drawingBox, ['width', 'height', 'top', 'left'])});
                    this.boxesChanged()
                }
                this.drawingBox = {
                    active: false,
                    top: 0,
                    left: 0,
                    height: 0,
                    width: 0
                }

            }
        },
        boxesChanged() {
            console.log("Change in boxes")
            let { width, height, naturalWidth, naturalHeight } = this.imgProperties;
            this.$emit('boxes', this.boxes.map(x => ({
                ...x,
                color: x.color,
                height: x.height / height,  // x should be a scalar
                width: x.width / width,     // x should be a scalar
                top: x.top / height,        // x should be a scalar
                left: x.left / width,       // x should be a scalar
            })))
        }
    },
    watch: {
        // boxes:{
        //     deep: true,
        //     handler(newVal) {
        //         let { width, height, naturalWidth, naturalHeight } = this.imgProperties;
        //         // Emit them as relative?
                
        //     } 
        // }
    }
}
</script>

<style lang="scss" scoped>
#image-wrapper {
    height: 640px;
    width: 640px;
    background-size: 640px !important;
    background-repeat: no-repeat;
    position: relative;
    background-size: cover;
    /* background-position: center; */
    -webkit-user-drag: none;
    -khtml-user-drag: none;
    -moz-user-drag: none;
    -o-user-drag: none;
    user-drag: none;
}
.nodrag {
    -webkit-user-drag: none;
-khtml-user-drag: none;
-moz-user-drag: none;
-o-user-drag: none;
user-drag: none;
}
</style>